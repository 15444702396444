
import Vue from "vue";

export default Vue.extend({
  data() {
    return {
      cards: [
        {
          titulo: "Sobre",
          descricao:
            "A TechOnne Tecnologia é uma empresa jovem e inovadora, com a missão de ajudar nossos clientes a terem sucesso, encontrando soluções inteligentes para os desafios de gestão, hardware, software e imagem, através de três pilares:",
          itens: [
            {
              titulo: "TechOnne Insights:",
              texto:
                "Visualize os dados da sua empresa de forma a gerarem valor, transformando-os em informação através de gráficos analíticos e alertas visuais. Também neste pilar, temos o desenvolvimento de softwares personalizados e criação de sites para para que seus clientes lhe encontre.",
            },
            {
              titulo: "TechOnne Fly:",
              texto:
                "Através de drones, capturamos imagens e videos aéreas de alta resolução para você, sua empresa, ou eventos.",
            },
            {
              titulo: "TechOnne Support:",
              texto:
                "Suporte em TI permitindo apoio total para pessoa física e jurídica quando se trata de soluções em computadores e upgrades de hardware. Somos uma empresa de tecnologia que prioriza o seu cliente, focado no mundo digital, levando qualidade para seu negócio.",
            },
          ],
        },
        {
          titulo: "Missão",
          descricao:
            "Nossa missão é promover o acesso a serviços com qualidade, suprindo as necessidades dos nossos clientes em hardware e desenvolvimento de sistemas, com ênfase na valorização de dados.",
          itens: [],
        },
        {
          titulo: "Visão",
          descricao:
            "Nossa visão se baseia na construção de um futuro baseado em informações. Promovendo a governança de dados, seguindo a LGPD em todos os nossos clientes.",
          itens: [],
        },
        {
          titulo: "Valores",
          descricao: "",
          itens: [
            { titulo: "Responsabilidade", texto: "" },
            { titulo: "Transparência", texto: "" },
            { titulo: "Inovação", texto: "" },
            { titulo: "Liderança", texto: "" },
            { titulo: "Qualidade", texto: "" },
          ],
        },
      ],
    };
  },
  methods: {},
});
